































import {commentFilters} from '@/data/comments'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

import Tabs from '@/components/widgets/Tabs.vue'
import DropdownFilter from '@/components/inputs/DropdownFilter.vue'

@Component({
  components: {
    Tabs,
    DropdownFilter
  }
})
export default class FlexibleDocumentIndex extends Vue {

  @Prop()
  private project!: ProjectResource

  @Prop()
  private document!: FlexibleDocumentResource

  private selectedCommentFilter: string | null = null
  private commentFilters: SelectItem[] = commentFilters

  private get isCommentPage(): boolean {
    return this.$route.name === 'projects-flexible-document-comments'
  }

  private get backRoute() {
    return { name: 'projects-flexible-document', query: { ...this.$route.query } }
  }

}
